import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useFirebase } from "contexts/FirebaseContext";
import { Typography, Box, Paper, Button } from "@mui/material";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../firebase";

const FoamerDashboard = () => {
  const { user } = useFirebase();
  const navigate = useNavigate();
  const [availableUnits, setAvailableUnits] = useState([]); // Store the list of available slugGunRefs (units)
  const [selectedUnit, setSelectedUnit] = useState(user.slugGunRef || ""); // Store the selected slugGunRef

  // Fetch the available slugGunRefs for the user's company
useEffect(() => {
  const fetchAvailableUnits = async () => {
    if (user.companyRef) {
      const q = query(
        collection(db, "machines"),
        where("companyRef", "==", user.companyRef)
      );
      const querySnapshot = await getDocs(q);
      const units = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setAvailableUnits(units);

      // Set the selected unit as the one stored in the user's slugGunRef by default
      if (user.slugGunRef && units.length > 0) {
        console.log("slugGunRef Value:", user.slugGunRef); // Debugging line
        console.log("slugGunRef Type:", typeof user.slugGunRef); // Debugging line

        const slugGunId = 
          typeof user.slugGunRef === "string" && user.slugGunRef.includes("/machines/")
            ? user.slugGunRef.split("/machines/")[1]
            : user.slugGunRef;

        const defaultUnit = units.find((unit) => unit.id === slugGunId);
        if (defaultUnit) {
          setSelectedUnit(slugGunId); // Pre-select user's slugGunRef after units are loaded
        }
      }
    }
  };

  fetchAvailableUnits();
}, [user.companyRef, user.slugGunRef]);

  // Handle unit selection change and update the user's slugGunRef in the database
  const handleUnitChange = async (unitId) => {
    try {
      setSelectedUnit(unitId);
  
      const userId = user?.id || user?.uid; // Ensure user ID is valid
      if (!userId) {
        throw new Error("User ID is undefined.");
      }
  
      const userRef = doc(db, "users", userId);
      const slugGunPath = `machines/${unitId}`; // No leading slash
      await updateDoc(userRef, { slugGunRef: slugGunPath });
      console.log("User's slugGunRef updated successfully.");
    } catch (error) {
      console.error("Error updating user slugGunRef:", error);
    }
  };
  /*
  const handleSubmit = async () => {
    try {
      if (selectedUnit) {
        const userDocRef = doc(db, "users", user.uid);
        const slugGunPath = `machines/${selectedUnit}`; // Consistent formatting
        await updateDoc(userDocRef, { slugGunRef: slugGunPath });
  
        // Navigate to the first form
        navigate("/dashboard/foamer/first-form");
      }
    } catch (error) {
      console.error("Error updating Firestore:", error);
    }
  };
*/

const handleSubmit = async () => {
  try {
    if (selectedUnit) {
      const userDocRef = doc(db, "users", user.uid);
      const slugGunPath = `machines/${selectedUnit}`; // Consistent formatting
      await updateDoc(userDocRef, { slugGunRef: slugGunPath });

      // Pass the selected unit as state
      navigate("/dashboard/foamer/first-form", { state: { selectedUnit } });
    }
  } catch (error) {
    console.error("Error updating Firestore:", error);
  }
};

  // Check if user is still loading
  if (!user) {
    return <Typography variant="h6">Loading user data...</Typography>;
  }

  // Pre-validation: Ensure the user has techRef, managerRef, and slugGunRef assigned
  if (!user.techRef || !user.managerRef || !user.slugGunRef) {
    return (
      <Box sx={{ p: 3 }}>
        <Paper sx={{ p: 2, backgroundColor: "#FFF5F4" }}>
          <Typography variant="h4" color="error" gutterBottom>
            Missing Required Information
          </Typography>
          <Typography color="error" variant="body1">
            Please contact your manager and ask them to make sure you have been
            assigned a Tech, Manager, and System to your profile before
            proceeding.
          </Typography>
          <Box mt={2}>
            {!user.techRef && (
              <Typography color="error" variant="h5">
                - Tech is not assigned.
              </Typography>
            )}
            {!user.managerRef && (
              <Typography color="error" variant="h5">
                - Manager is not assigned.
              </Typography>
            )}
            {!user.slugGunRef && (
              <Typography color="error" variant="h5">
                - System is not assigned.
              </Typography>
            )}
          </Box>
        </Paper>
      </Box>
    );
  }

// Check if there are available units (slugGunRefs) for the user's company
if (availableUnits.length === 0) {
  return (
    <Box sx={{ p: 3 }}>
      <Paper sx={{ p: 2, backgroundColor: "#FFF5F4" }}>
        <Typography variant="h4" color="error" gutterBottom>
          No Units Available
        </Typography>
        <Typography variant="body1" color="error">
          No slug gun units are currently available for your company. Please
          contact your manager.
        </Typography>
      </Paper>
    </Box>
  );
}

// If there's only one unit, automatically set it as the selected unit
if (availableUnits.length === 1) {
  const singleUnit = availableUnits[0];
  if (selectedUnit !== singleUnit.id) {
    setSelectedUnit(singleUnit.id); // Automatically select the only unit
    handleUnitChange(singleUnit.id); // Update Firestore if needed
  }
  return (
    <Box sx={{ p: 3 }}>
      <Paper sx={{ p: 2 }}>
        <Typography variant="h5" gutterBottom>
          You're currently using: {singleUnit.nickname || singleUnit.machineName}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          sx={{ mt: 2 }}
          onClick={handleSubmit}
        >
          Proceed
        </Button>
      </Paper>
    </Box>
  );
}

  // Check if there are multiple units (slugGunRefs) for the company and allow selection
  if (availableUnits.length > 1) {
    return (
      <Box sx={{ p: 3 }}>
        <Paper sx={{ p: 2 }}>
          <Typography variant="h5" gutterBottom>
            Select the Unit You're Shooting On:
          </Typography>
          <Box>
            {availableUnits.map((system) => (
              <Button
                key={system.id}
                variant={selectedUnit === system.id ? "contained" : "outlined"} // Primary for selected, outlined for others
                color={selectedUnit === system.id ? "info" : "secondary"} // Primary for selected, secondary for others
                fullWidth
                sx={{ mt: 1 }} // Add margin between buttons
                onClick={() => handleUnitChange(system.id)}
              >
                {/* Display nickname if available, otherwise machineName - system */}
                {system.nickname
                  ? `${system.nickname}`
                  : `${system.machineName} - ${system.system}`}
              </Button>
            ))}
          </Box>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            sx={{ mt: 2 }}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </Paper>
      </Box>
    );
  }

  // Render the nested routes (forms) after selecting the correct unit
  return <Outlet />;
};

export default FoamerDashboard;
