// // utils/calculateRatios.js
// export const calculateRatios = (polyWeight, isoWeight, durationBagShot) => {
//   const poundsPerSecondStepOne = 60 / durationBagShot;
//   const poundsPerSecondStepTwo = polyWeight * poundsPerSecondStepOne;
//   const poundsPerMinute = poundsPerSecondStepTwo / 453.592;
//   const poundsPerSecond = poundsPerMinute / 60;

//   // Calculate the ratio of ISO Weight to PolyWeight
//   const ratio = polyWeight / isoWeight;

//   return {
//     poundsPerSecond: poundsPerSecond.toFixed(2),
//     poundsPerMinute: poundsPerMinute.toFixed(2),
//     ratio: ratio.toFixed(2), // Return the calculated ratio, formatted to two decimal places
//   };
// };

// utils/calculateRatios.js
export const calculateRatios = (polyWeight, isoWeight, durationBagShot) => {
  // Ensure inputs are valid numbers to prevent errors
  if (!polyWeight || !isoWeight || !durationBagShot) {
    console.error("Invalid inputs provided to calculateRatios");
    return {
      poundsPerSecond: "0.00",
      poundsPerMinute: "0.00",
      ratio: "0.00",
    };
  }

  // Convert inputs to integers (in case they are strings)
  const parsedPolyWeight = parseInt(polyWeight, 10);
  const parsedIsoWeight = parseInt(isoWeight, 10);

  // Log parsed weights to ensure they're correct
  console.log("Parsed PolyWeight:", parsedPolyWeight);
  console.log("Parsed IsoWeight:", parsedIsoWeight);

  // Step 1: Calculate total weight (ISO + Poly) in grams
  const totalWeight = parsedIsoWeight + parsedPolyWeight;
  console.log("Total Weight (grams):", totalWeight);  // Log total weight

  // Step 2: Calculate the scaling factor based on bagshot duration (60 / durationBagShot)
  const timing = 60 / durationBagShot; // This gives us the per-minute scaling factor
  console.log("Timing (Scaling Factor):", timing);  // Log the scaling factor

  // Step 3: Calculate total weight per minute in grams
  const weightPerMinuteInGrams = totalWeight * timing;
  console.log("Weight Per Minute (grams):", weightPerMinuteInGrams);  // Log weight per minute in grams

  // Step 4: Convert the weight per minute from grams to pounds
  const poundsPerMinute = weightPerMinuteInGrams / 453.592;
  console.log("Pounds Per Minute:", poundsPerMinute);  // Log pounds per minute

  // Step 5: Convert pounds per minute to pounds per second
  const poundsPerSecond = poundsPerMinute / 60;
  console.log("Pounds Per Second:", poundsPerSecond);  // Log pounds per second

  // Step 6: Calculate the ratio of PolyWeight to ISOWeight
  const ratio = parsedPolyWeight / parsedIsoWeight;
  console.log("Ratio of PolyWeight to ISOWeight:", ratio);  // Log ratio

  // Return the calculated values, formatted to two decimal places
  return {
    poundsPerSecond: poundsPerSecond.toFixed(2),
    poundsPerMinute: poundsPerMinute.toFixed(2),
    ratio: ratio.toFixed(2),
  };
};
