import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useFirebase } from "contexts/FirebaseContext";
import {
  Alert,
  Box,
  Divider,
  FormHelperText,
  Grid,
  InputLabel,
  TextField,
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
  Typography,
  Button,
  Stack,
} from "@mui/material";
import MainCard from "components/MainCard";
import AnimateButton from "components/@extended/AnimateButton";
import { addDoc, collection, serverTimestamp, doc } from "firebase/firestore";
import { auth, db } from "../firebase";
import { useFormik } from "formik";
import * as yup from "yup";
import "../assets/css/styles.css";
import ResultCard from "./ResultCard";
import { calculateRatioData, prepareDocData } from "../utils/ratioUtils";
import { fetchSlugGunSystemData } from "../utils/dataFetchers";
import PreventBackNavigation from "./PreventBackNavigation";

// Define your validation schema
const validationSchema = yup.object({
  ISOWeight: yup.number().required("ISO Weight is required"),
  PolyWeight: yup.number().required("Poly Weight is required"),
  bagWeight: yup.number().required("Bag Weight is required"),
});

const FirstFormComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useFirebase();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [docId, setDocId] = useState(null);
  const [slugGunSystemData, setSlugGunSystemData] = useState(null);
  const [isSlugGunLoading, setIsSlugGunLoading] = useState(true);

  // Extract selectedUnit from location state
  const { selectedUnit } = location.state || {};

  /*
  useEffect(() => {
    const fetchData = async () => {
      if (user?.slugGunRef) {
        setIsSlugGunLoading(true);
        const data = await fetchSlugGunSystemData(user.slugGunRef);
        setSlugGunSystemData(data);
        setIsSlugGunLoading(false);
      }
    };

    fetchData();
  }, [user?.slugGunRef]);
  */
  useEffect(() => {
    const fetchData = async () => {
      const slugGunRef = selectedUnit
        ? `machines/${selectedUnit}` // Use selectedUnit if available
        : user?.slugGunRef; // Fallback to user's slugGunRef

      if (slugGunRef) {
        setIsSlugGunLoading(true);
        try {
          const data = await fetchSlugGunSystemData(slugGunRef);
          setSlugGunSystemData(data);
        } catch (error) {
          console.error("Error fetching slug gun system data:", error);
          setIsError(true);
          setErrorMessage("Failed to load slug gun system data.");
        } finally {
          setIsSlugGunLoading(false);
        }
      }
    };

    fetchData();
  }, [selectedUnit, user?.slugGunRef]);

  const formik = useFormik({
    initialValues: {
      ISOWeight: "",
      PolyWeight: "",
      bagWeight: "",
      visualCheck: "yes", // Default value
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const currentUser = auth.currentUser;
        if (!currentUser) throw new Error("User not authenticated");

        const ratioData = calculateRatioData(
          values,
          slugGunSystemData,
          slugGunSystemData?.durationBagShot || 0
        );
        const nickname = slugGunSystemData?.nickname || `Unit ${selectedUnit}`;
        const docData = {
        ...prepareDocData(
          values,
          user,
          currentUser,
          slugGunSystemData,
          ratioData,
          "firstForm"
        ),
        nickname,
        };
        // console.log("ratioData:", ratioData);
        // console.log("docData being sent to Firestore:", docData);

        const docRef = await addDoc(collection(db, "formEntries"), docData);
        setDocId(docRef.id);

        setIsSubmitted(true);
        setIsError(false);

        if (ratioData.isOffRatio) {
          // console.log("Navigating to second form");
          navigate("/dashboard/foamer/second-form", {
            state: { nickname: slugGunSystemData?.nickname },
          });          
        } else {
          // If on-ratio, display ResultCard with form for issue and fix
          setIsSubmitted(true);
        }
      } catch (error) {
        console.error("Error writing document to Firestore", error);
        setIsError(true);
        setErrorMessage(
          "An error occurred while submitting the form: " + error.message
        );
        setIsSubmitted(false);
      }
    },
  });
/*
  const gunNumber = isSlugGunLoading
    ? "Loading..."
    : slugGunSystemData
      ? `Slug Gun being used = ${slugGunSystemData.machineName}`
      : "No slugGun assigned";

  const durationBagShot = slugGunSystemData?.durationBagShot || "10"; // Default to 10 if not available
*/

const gunNumber = isSlugGunLoading
  ? "Loading..."
  : slugGunSystemData
  ? `Slug Gun being used = ${
      slugGunSystemData.machineName + ' - ' + (slugGunSystemData.nickname || `Unit ${selectedUnit}`)
    }`
  : selectedUnit
  ? `Selected Unit = ${selectedUnit}`
  : "No slugGun assigned";

const durationBagShot = slugGunSystemData?.durationBagShot || "10"; // Default to 10 if not available

  // Prevent browser back navigation
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = "";
    };

    const handlePopState = (event) => {
      navigate("/dashboard/foamer/first-form"); // Or any desired path to redirect
      event.preventDefault();
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("popstate", handlePopState);
    };
  }, [navigate]);

  return (
    <>
      <PreventBackNavigation />
      <Grid item xs={12} lg={8} sx={{ margin: "auto" }}>
        {isSubmitted ? (
          // Render success banner
          <Grid item xs={12} lg={8} sx={{ margin: "auto" }}>
            <Grid container spacing={3} alignItems="center">
              <Grid item xs={12}>
                <ResultCard
                  bagWeight={formik.values.bagWeight}
                  durationBagShot={slugGunSystemData?.durationBagShot}
                />
              </Grid>
            </Grid>
          </Grid>
        ) : (
          // Render form
          <form onSubmit={formik.handleSubmit}>
            <Grid item xs={12} lg={8} sx={{ margin: "auto" }}>
              <MainCard title={gunNumber}>
                <Grid container spacing={3} alignItems="center">
                  <Grid item xs={12}>
                    <Typography variant="h3" component="div" sx={{ mb: 3 }}>
                      {durationBagShot} Second Ratio Shot:
                    </Typography>
                    <Grid container spacing={2} alignItems="center">
                      <Grid
                        item
                        xs={12}
                        sm={3}
                        lg={4}
                        sx={{ pt: { xs: 2, sm: "0 !important" } }}
                      >
                        <InputLabel
                          sx={{
                            textAlign: { xs: "left", sm: "right" },
                            fontWeight: 900,
                          }}
                        >
                          ISO Weight :
                        </InputLabel>
                      </Grid>
                      <Grid item xs={12} sm={9} lg={6}>
                        <TextField
                          fullWidth
                          id="ISOWeight"
                          name="ISOWeight"
                          placeholder="grams"
                          type="tel"
                          value={formik.values.ISOWeight}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.ISOWeight &&
                            Boolean(formik.errors.ISOWeight)
                          }
                          helperText={
                            formik.touched.ISOWeight && formik.errors.ISOWeight
                          }
                        />
                        <FormHelperText sx={{ mb: 2 }}>
                          Please enter the weight in grams
                        </FormHelperText>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={3}
                        lg={4}
                        sx={{ pt: { xs: 2, sm: "0 !important" } }}
                      >
                        <InputLabel
                          sx={{
                            textAlign: { xs: "left", sm: "right" },
                            fontWeight: 900,
                          }}
                        >
                          Poly Weight :
                        </InputLabel>
                      </Grid>
                      <Grid item xs={12} sm={9} lg={6}>
                        <TextField
                          fullWidth
                          id="PolyWeight"
                          name="PolyWeight"
                          placeholder="grams"
                          type="tel"
                          value={formik.values.PolyWeight}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.PolyWeight &&
                            Boolean(formik.errors.PolyWeight)
                          }
                          helperText={
                            formik.touched.PolyWeight &&
                            formik.errors.PolyWeight
                          }
                        />
                        <FormHelperText sx={{ mb: 2 }}>
                          Please enter the weight in grams
                        </FormHelperText>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={3}
                        lg={4}
                        sx={{ pt: { xs: 2, sm: "0 !important" } }}
                      >
                        <InputLabel
                          sx={{
                            textAlign: { xs: "left", sm: "right" },
                            fontWeight: 900,
                          }}
                        >
                          Bag Weight :
                        </InputLabel>
                      </Grid>
                      <Grid item xs={12} sm={9} lg={6}>
                        <TextField
                          fullWidth
                          id="bagWeight"
                          name="bagWeight"
                          placeholder="grams"
                          type="tel"
                          value={formik.values.bagWeight}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.bagWeight &&
                            Boolean(formik.errors.bagWeight)
                          }
                          helperText={
                            formik.touched.bagWeight && formik.errors.bagWeight
                          }
                        />
                        <FormHelperText sx={{ mb: 3 }}>
                          Please enter the weight in grams
                        </FormHelperText>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant="h5"
                        component="div"
                        sx={{ mt: 3, mb: 2 }}
                      >
                        Visual Check:
                      </Typography>
                      <FormControl
                        fullWidth
                        error={
                          formik.touched.visualCheck &&
                          Boolean(formik.errors.visualCheck)
                        }
                      >
                        <Grid container spacing={2} alignItems="center">
                          <Grid item xs={12}>
                            <InputLabel
                              shrink={false} // Disable shrink behavior
                              sx={{
                                fontWeight: 900,
                                textAlign: { xs: "left", sm: "right" },
                                mb: { xs: 1, sm: 0 },
                                fontSize: "1rem", // Adjust font size
                                color: "#aaa", // Ensure label color is consistent
                                display: "block", // Makes the label stay above the radio buttons
                                marginBottom: "48px", // Adjust spacing
                              }}
                            >
                              Does it pass the visual check:
                            </InputLabel>
                          </Grid>
                          <Grid item xs={12} sm={9}>
                            <RadioGroup
                              row
                              aria-labelledby="visual-check-label"
                              name="visualCheck"
                              value={formik.values.visualCheck}
                              onChange={formik.handleChange}
                              sx={{
                                justifyContent: {
                                  xs: "flex-start",
                                  sm: "flex-start",
                                },
                                "& .MuiFormControlLabel-root": {
                                  // Style form control labels
                                  marginRight: "36px", // Adjust spacing
                                  marginLeft: "12px", // Adjust spacing
                                  marginTop: "24px", // Adjust spacing
                                },
                                "& .MuiRadio-root": {
                                  color: "#1976d2", // Style radio button color
                                  marginRight: "8px", // Adjust spacing
                                },
                                "& .MuiTypography-root": {
                                  // Style label text
                                  fontSize: "1rem", // Keep consistent font size
                                  color: "#aaa", // Keep text color black
                                },
                              }}
                            >
                              <FormControlLabel
                                value="yes"
                                control={<Radio />}
                                label="Yes"
                                sx={{ mr: { xs: 2, sm: 4 } }}
                              />
                              <FormControlLabel
                                value="no"
                                control={<Radio />}
                                label="No"
                              />
                            </RadioGroup>
                          </Grid>
                          {formik.touched.visualCheck &&
                            formik.errors.visualCheck && (
                              <Grid item xs={12}>
                                <Alert severity="error" sx={{ mt: 2 }}>
                                  {formik.errors.visualCheck}
                                </Alert>
                              </Grid>
                            )}
                        </Grid>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <Stack direction="row" justifyContent="flex-end">
                      <AnimateButton>
                        <Button variant="contained" type="submit">
                          Submit
                        </Button>
                      </AnimateButton>
                    </Stack>
                  </Grid>
                </Grid>
              </MainCard>
            </Grid>
          </form>
        )}
        {/* Close of isSubmitted */}
      </Grid>
    </>
  );
};

export default FirstFormComponent;
