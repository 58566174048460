// dataFetchers.js
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";  // Ensure db is imported from the Firebase config

export const fetchSlugGunSystemData = async (slugGunRef) => {
  if (slugGunRef) {
    // Check if slugGunRef is a string (e.g., path), then convert it to a document reference
    const ref = typeof slugGunRef === "string" ? doc(db, slugGunRef) : slugGunRef;

    // Fetch the document data
    const slugGunDoc = await getDoc(ref);
    
    if (slugGunDoc.exists()) {
      return slugGunDoc.data();  // Return the document data
    } else {
      throw new Error("Slug gun document not found");  // Handle case when the document doesn't exist
    }
  } else {
    throw new Error("Slug gun reference not found");  // Handle case when slugGunRef is not provided
  }
};

export const getContactData = async (contactRef) => {
  if (contactRef) {
    const contactDocRef = typeof contactRef === "string" ? doc(db, contactRef) : contactRef;
    const contactSnap = await getDoc(contactDocRef);
    if (contactSnap.exists()) {
      return contactSnap.data();
    } else {
      throw new Error("Contact document not found");
    }
  } else {
    throw new Error("Contact reference not found");
  }
};
